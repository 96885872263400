<template>
  <Dialog :name="name" width="420px" class-own="dialog-detach-truck">
    <template #title>
      <div style="font-size: 18px; font-weight: 400">Вы уверены?</div>
    </template>

    <div class="detach__title">Выберите причину возврата на парковку:</div>

    <el-radio-group v-model="reason" class="detach__radio">
      <el-radio
        v-for="reasonItem in reasonsList"
        :key="reasonItem.code"
        :label="reasonItem.code"
        border
      >
        {{ reasonItem.name }}
      </el-radio>
    </el-radio-group>

    <div class="m-b-25">
      <div class="detach__warning">
        <i
          class="el-icon-error detach__warning-icon"
          style="font-size: 20px; color: red"
        />

        <div class="detach__warning-text">
          {{ warningText }}
        </div>
      </div>
    </div>

    <div class="detach__buttons">
      <iq-button
        class="detach__buttons-item"
        color="white"
        @onClick="closeDialog"
      >
        Отмена
      </iq-button>
      <iq-button
        class="detach__buttons-item"
        :disabled="!reason"
        @onClick="handleDetachTruck"
      >
        Да
      </iq-button>
    </div>
  </Dialog>
</template>

<script>
import { GET_PARKMAN_DETACH_REASONS } from '@/views/parkman/store/actions'
import { PARKMAN_DETACH_TRUCK } from '@/constants/dialogs'
import { mapGetters } from 'vuex'
import Dialog from '@/UI/dialog/Dialog'
import IqButton from '@/UI/buttons/IqButton.vue'

export default {
  name: 'ParkmanDetachTruck',
  components: { IqButton, Dialog },
  data() {
    return {
      name: PARKMAN_DETACH_TRUCK,
      reason: 0,
    }
  },
  computed: {
    ...mapGetters({
      reasonsList: GET_PARKMAN_DETACH_REASONS,
    }),
    timeslot() {
      return this.getDialog(this.name)?.data || {}
    },
    warningText() {
      return `Отказать в проезде авто № ${this.timeslot.plate_truck} ?`
    },
  },
  methods: {
    async handleDetachTruck() {
      const detached = {
        timeslot_id: this.timeslot.timeslot_id,
        plate_truck: this.timeslot.plate_truck,
        detachment_reason_code: this.reason,
      }

      this.$emit('detach', detached)
      this.closeDialog()
    },
    closeDialog() {
      this.reason = ''
      this.setDialog({ name: this.name }, false)
    },
  },
}
</script>

<style lang="sass" scoped>
.dialog-detach-truck
  ::v-deep .el-dialog__header
    background: $color-blue-light

  .detach__title
    margin-bottom: 16px
    font-size: 16px
    font-weight: 700

  .detach__radio
    display: flex
    flex-direction: column
    row-gap: 8px
    margin-bottom: 40px
    .el-radio, .el-radio.is-bordered+.el-radio.is-bordered
      margin-left: 0
      margin-right: 0

  .detach__warning
    display: flex
    align-items: center
    gap: 16px
    &-icon
      font-size: 20px
      color: $color-red
    &-text
      font-size: 16px
      font-weight: 500

  .detach__buttons
    display: flex
    justify-content: space-between
    align-items: center
    &-item
      width: 48%
</style>
