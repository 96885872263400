var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "Dialog",
    {
      attrs: {
        name: _vm.name,
        width: "420px",
        "class-own": "dialog-detach-truck",
      },
      scopedSlots: _vm._u([
        {
          key: "title",
          fn: function () {
            return [
              _c(
                "div",
                { staticStyle: { "font-size": "18px", "font-weight": "400" } },
                [_vm._v("Вы уверены?")]
              ),
            ]
          },
          proxy: true,
        },
      ]),
    },
    [
      _c("div", { staticClass: "detach__title" }, [
        _vm._v("Выберите причину возврата на парковку:"),
      ]),
      _c(
        "el-radio-group",
        {
          staticClass: "detach__radio",
          model: {
            value: _vm.reason,
            callback: function ($$v) {
              _vm.reason = $$v
            },
            expression: "reason",
          },
        },
        _vm._l(_vm.reasonsList, function (reasonItem) {
          return _c(
            "el-radio",
            {
              key: reasonItem.code,
              attrs: { label: reasonItem.code, border: "" },
            },
            [_vm._v(" " + _vm._s(reasonItem.name) + " ")]
          )
        }),
        1
      ),
      _c("div", { staticClass: "m-b-25" }, [
        _c("div", { staticClass: "detach__warning" }, [
          _c("i", {
            staticClass: "el-icon-error detach__warning-icon",
            staticStyle: { "font-size": "20px", color: "red" },
          }),
          _c("div", { staticClass: "detach__warning-text" }, [
            _vm._v(" " + _vm._s(_vm.warningText) + " "),
          ]),
        ]),
      ]),
      _c(
        "div",
        { staticClass: "detach__buttons" },
        [
          _c(
            "iq-button",
            {
              staticClass: "detach__buttons-item",
              attrs: { color: "white" },
              on: { onClick: _vm.closeDialog },
            },
            [_vm._v(" Отмена ")]
          ),
          _c(
            "iq-button",
            {
              staticClass: "detach__buttons-item",
              attrs: { disabled: !_vm.reason },
              on: { onClick: _vm.handleDetachTruck },
            },
            [_vm._v(" Да ")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }